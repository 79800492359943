import PropTypes from 'prop-types';
import {useMemo} from 'react';
import {renderToString} from 'react-dom/server';
import {getServerState} from 'react-instantsearch';
import I18nWrapper from '../../lib/i18n';
import {AlgoliaContext} from '../../context/AlgoliaContext';
import {getPageType, CategoryPage} from '../../components/Discovery/CategoryPage';
import ServerState from '../../components/types/ServerState';

const CATEGORY = 'Mockups';

function MockupsCategory({serverState, serverUrl, query}) {
  MockupsCategory.metadata.pageCategory = CATEGORY;

  const algoliaValue = useMemo(
    () => ({
      serverState,
      serverUrl,
    }),
    []
  );

  return (
    <AlgoliaContext.Provider value={algoliaValue}>
      <CategoryPage
        query={query}
        category={CATEGORY.toLowerCase()}
        SEO={{
          pageCategory: CATEGORY,
          pageType: getPageType(query),
        }}
      />
    </AlgoliaContext.Provider>
  );
}

export async function getServerSideProps({req, query}) {
  const PAGE_TYPE_MAIN_SEARCH = 'Main Search';
  const PAGE_TYPE_CATEGORY = 'Category Page';
  const protocol = req.headers.referer?.split('://')[0] || 'https';
  const serverUrl = `${protocol}://${req.headers.host}${req.url}`;
  const serverState = await getServerState(
    <I18nWrapper>
      <MockupsCategory serverUrl={serverUrl} />
    </I18nWrapper>,
    {
      renderToString,
    }
  );

  MockupsCategory.metadata.pageType = query?.search ? PAGE_TYPE_MAIN_SEARCH : PAGE_TYPE_CATEGORY;

  return {
    props: {
      serverState,
      serverUrl,
      query: query.search || '',
    },
  };
}

export default MockupsCategory;

MockupsCategory.defaultProps = {
  serverUrl: '',
  query: '',
};

MockupsCategory.propTypes = {
  serverState: ServerState.isRequired,
  serverUrl: PropTypes.string,
  query: PropTypes.string,
};

MockupsCategory.metadata = {
  pageCategory: null,
};
